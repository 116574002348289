import { Link } from "gatsby"
import React from "react"

const navStyle = {
  margin: `0 auto`,
  paddingBottom: `1em`,
  display: `flex`,
  justifyContent: `space-evenly`,
};

const linkStyle = {
  fontFamily: 'Ropa Sans, sans-serif',
  fontWeight: `400`,
  fontSize: `1em`,
  color: `black`,
  textDecoration: `none`,
};

const activeLinkStyle = {
  textDecoration: `underline`
};

const Nav = () => (
  <nav
    style={ navStyle }
  >
    <Link 
      to="/about" 
      style={ linkStyle }
      activeStyle={ activeLinkStyle }
    >
      ABOUT
    </Link>
    <Link 
      to="/shows" 
      style={ linkStyle }
      activeStyle={ activeLinkStyle }
    >
      SHOWS
    </Link>
  </nav>
)

export default Nav

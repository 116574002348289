import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const footerStyle = {
  marginTop: `2em`,
  fontFamily: 'Ropa Sans, sans-serif',
  fontWeight: `400`,
  textAlign: `center`,
};

const footerNavStyle = {
  margin: `0 auto`,
  paddingBottom: `1em`,
  display: `flex`,
  justifyContent: `space-evenly`,
  maxWidth: 175
};

const iconStyle = {
  color: `#313639`,
}

const Footer = () => (
  <footer
    style={ footerStyle }
  >
    <div style={ footerNavStyle }>
      <a href="https://www.facebook.com/andrewplanmusic" >
        <FontAwesomeIcon icon={["fab", "facebook"]} style={ iconStyle } size="1x"/>
      </a>
      <a href="https://www.instagram.com/andrewplanmusic" >
        <FontAwesomeIcon icon={["fab", "instagram"]} style={ iconStyle } size="1x"/>
      </a>
      <a href="https://www.youtube.com/andrewplanmusic" >
        <FontAwesomeIcon icon={["fab", "youtube"]} style={ iconStyle } size="1x"/>
      </a>
      <a href="https://open.spotify.com/artist/78qdylNcM5VHkVI3GiTHhm?si=Yyu0Asv9RcW9nzrUL6Luag" >
        <FontAwesomeIcon icon={["fab", "spotify"]} style={ iconStyle } size="1x"/>
      </a>
    </div>
    <div style={{ marginBottom: `2.2em` }}>
      © {new Date().getFullYear()} Heartwork Music LLC
    </div>
  </footer>
)

export default Footer

import PropTypes from "prop-types"
import Link from "gatsby-link"
import React from "react"
import LogoBanner from './logo_banner'

const Header = ({ siteTitle }) => (
  <header
    style={{
      // marginBottom: `1.45rem`,
    }}
  >
    <div
      css={{
        // margin: `0 auto`,
        maxWidth: 260,
        padding: `1.45rem 1.0875rem 1rem 1.0875rem`,
        display: 'block',
        '@media (min-width: 500px)': {
          maxWidth: 300
        }
      }}
    >
      <Link to='/'>
        <LogoBanner />  
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
